module.exports = new Promise(resolve => {
                    const config = window.hlAdminCoreRemoteConfig;
                    const script = document.createElement('script')
                    script.src = config.entryUrl;
                    script.onload = () => {
                        const proxy = {
                            get: (request) => window[config.entryScope].get(request),
                            init: (arg) => {
                                try {
                                    return window[config.entryScope].init(arg)
                                } catch(e) {
                                    console.log('remote container', config.entryScope, 'already initialized')
                                }
                            }
                        }
                        resolve(proxy)
                    }
                    document.head.appendChild(script);
                })
            ;